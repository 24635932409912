import React, { useState } from "react";
import { Rating } from "primereact/rating";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as HeartSolid } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";

// Will Rander The List Of Rate Stars 
export default function RatingStar(props) {

    const { rate, isDisabled } = props

    const [value, setValue] = useState(rate || 4);

    return (
        <div className="card flex justify-content-center">
            <Rating
                value={value}
                disabled={isDisabled}
                onChange={(e) => setValue(e.value)}
                cancel={false}
                onIcon={<FontAwesomeIcon icon={HeartSolid} className="text-[var(--light)]" />}
                offIcon={<FontAwesomeIcon icon={faHeart} className="text-[var(--light)]" />}
            />
        </div>
    );
}


RatingStar.propTypes = {
    rate: PropTypes.number,
    isDisabled: PropTypes.bool,
}