import React, { useState, useEffect } from 'react';

const Timer = () => {
    // State variables for days, hours, minutes, and seconds
    const [days, setDays] = useState(10);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    // Function to format the time value with leading zeros
    const formatTime = (value) => {
        return value.toString().padStart(2, '0');
    };

    // Function to calculate and update the time values
    const getTime = () => {
        const time = days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds - 1;

        setDays(Math.floor(time / (24 * 60 * 60)));
        setHours(Math.floor((time / (60 * 60)) % 24));
        setMinutes(Math.floor((time / 60) % 60));
        setSeconds(time % 60);
    };

    // Run the getTime function every second using useEffect
    useEffect(() => {

        const interval = setInterval(() => getTime(), 1000);

        return () => clearInterval(interval);
        
    }, [seconds]);

    return (
        <div className="timer flex items-center justify-between">
            {/* Timer component with seconds */}
            <p className='p-2 sm:w-[92px] px-4 sm:px-8 me-2 text-white border border-white text-[13px] rounded-[5px] font-medium flex items-center flex-col justify-center'>
                <span className='mb-0'>{formatTime(seconds)}</span>
                <span>ثانيه</span>
            </p>

            {/* Timer component with minutes */}
            <p className='p-2 sm:w-[92px] px-4 sm:px-8 me-2 text-white border border-white text-[13px] rounded-[5px] font-medium flex items-center flex-col justify-center'>
                <span className='mb-0'>{formatTime(minutes)}</span>
                <span>دقيقه</span>
            </p>

            {/* Timer component with hours */}
            <p className='p-2 sm:w-[92px] px-4 sm:px-8 me-2 text-white border border-white text-[13px] rounded-[5px] font-medium flex items-center flex-col justify-center'>
                <span className='mb-0'>{formatTime(hours)}</span>
                <span>ساعه</span>
            </p>

            {/* Timer component with days */}
            <p className='p-2 sm:w-[92px] px-4 sm:px-8 me-2 text-white border border-white text-[13px] rounded-[5px] font-medium flex items-center flex-col justify-center'>
                <span className='mb-0'>{formatTime(days)}</span>
                <span>يوم</span>
            </p>
        </div>
    );
};

export default Timer;