import React from 'react'
import Rating from '../Rating'
import AddToFavoriteIcon from '../AddToFavoriteIcon'
import AddToCart from '../AddToCartButton/AddToCart'


export default function GloabelProductCard({ classNames, imageName }) {

    return (
        <div className={`h-[550px] overflow-hidden relative bg-[#f2f2f2] rounded-[3px] p-product-card ${classNames}`} style={{ '--rating-color': "#FFB800" }}>

            <img src={require(`../../../assets/images/${imageName}.png`)} className='w-full h-full absolute left-0 top-0 object-fill' />

            <div className='absolute bottom-[-300px] w-full p-3 hidden-part' style={{ background: 'linear-gradient(180deg, rgba(156, 33, 162, 0.50) 0%, var(--primary) 100%)' }}>

                <h1 className='text-[24px] text-white font-medium mb-1'>برش أي شادو</h1>

                <div className='card-rating flex items-center'>

                    <Rating />

                    <p className='ms-3 text-[13px] mb-[-3px] text-[#af89b0]'>(4.5)</p>

                </div>

                <button className='bg-white p-2 my-3 w-full flex items-center justify-center rounded-[5px]'>

                    <h6 className='text-[var(--danger-color)] text-[24px] font-bold ms-3'>250.00 رس</h6>

                    <p className='gray-text !text-[#A5A5A5] ms-3' style={{ textDecoration: 'line-through' }}>350.00 ر.س</p>

                </button>

                <AddToCart classNames={'w-full !bg-transparent !border !border-white !rounded-[3px]'} />

            </div>

            <AddToFavoriteIcon classNames={'!rounded-full !mt-2 !bg-white'} />

        </div>
    )
}
