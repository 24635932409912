import './assets/styles/App.css';
import './assets/styles/responsive.css';
import { Header, Footer, Breadcrumb } from './views/layouts';
import routes from './routes/routes';
import { ScrollReset, UpButton } from './views/components';
import { useEffect, useState } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";

function App() {

  const [activeMnueLang, setActiveMnueLang] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 1300 });
    AOS.refresh();
  }, []);

  return (

    <div className="App">

      <Header activeMnueLang={activeMnueLang} setActiveMnueLang={setActiveMnueLang} />

      <Breadcrumb />

      <ScrollReset />

      <UpButton />

      {routes()}

      <Footer></Footer>

    </div>

  );
}

export default App;
