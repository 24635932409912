// importing require modules
import PropTypes from 'prop-types';
import { Dropdown } from 'primereact/dropdown';

// Render  dropdown Component
export default function DropDownMnue(props) {

    const { items, selectedItem, setSelectedItem, placeholder , classNames } = props;

    // Return The Drop Downdown mnue 
    return (
        <Dropdown value={selectedItem} onChange={(e) => setSelectedItem(e.value)} options={items} optionLabel="name"
            placeholder={placeholder} className={`w-full md:w-14rem ${classNames}`} />
    )
}

// Add PropTypes validation
DropDownMnue.propTypes = {
    items: PropTypes.array,
    selectedItem: PropTypes.object,
    selectedItem: PropTypes.func,
    placeholder: PropTypes.string,
    classNames: PropTypes.string,
}