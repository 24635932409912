import { useEffect } from "react";

const useCloseMnue = (showFilter, setShowFilter) => {

    const hideMnueForLargeScreen = () => {

        if (window.innerWidth >= 1024) {

            setShowFilter(false);

        }

    }

    useEffect(() => {

        hideMnueForLargeScreen();

        window.addEventListener('resize', _ => hideMnueForLargeScreen());

        // clean up
        return () => window.removeEventListener('resize', _ => hideMnueForLargeScreen());

    }, []);

    useEffect(() => {

        window.addEventListener('click', () => {

            if (showFilter) setShowFilter(false);

        });

        // clean up
        return () => window.removeEventListener('click', () => { });

    }, []);

};

const useApplyPanelHeightRestriction = (showFilter) => {

    useEffect(() => {

        const mnuePanel = document.querySelector('.panel-mnue');

        // add event listener on mount
        if (showFilter) {

            document.querySelector("#root").style.cssText += `max-height: ${mnuePanel.getClientRects()[0].height}px !important; overflow: hidden;`;

        } else {

            document.querySelector("#root").style.cssText += "max-height: initial !important; overflow: auto;";

        }

        // Remove event listener on unmount
        return () => {

            // Clear the styles
            document.querySelector("#root").style.maxHeight = "";

            document.querySelector("#root").style.overflow = "";

        };
    }, [showFilter]);

};

const useResetScroll = (router) => {

    useEffect(() => {

        window.scrollTo(0, 0);

    }, [router.pathname]);

}

const useUpButton = (setShowBtn) => {

    // control display button
    const toggleShow = () => {

        if (window.scrollY > 750) {

            setShowBtn(true);

        } else {

            setShowBtn(false);

        }
    }

    useEffect(() => {

        toggleShow();

        window.addEventListener('scroll', toggleShow);

        // clean up
        return () => window.removeEventListener('scroll', toggleShow);

    }, []);
}

export default function useEffectControlar() {
    return { useCloseMnue, useApplyPanelHeightRestriction, useResetScroll, useUpButton }
}
