import { faFacebookF, faInstagram, faSnapchat, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { accountData, importantLinksData, contactUsData, renderLists } from '../../data';


// That's Will Render The Links Of Footer 
export default function FooterLinks() {
    return (
        <div className='my-10 p-6'>

            <div className='container flex items-center'>

                <div className='grid grid-cols-12 gap-5'>

                    <div className='col-span-12 lg:col-span-3'>

                        <h2 className='font-medium mb-4 py-3 bg-[#b04db5] ps-3 text-white'>عن متجرنا</h2>

                        <p className='gray-text leading-6'>
                            متجر سلة من افضل المتاجر التى تقوم ببيع المنتجات الرقمية بأفضل الاسعار وماركات عالمية تسوق الان واطلع على المزيد من التصاميم واستمتع بأفضل العروض والخصومات
                        </p>

                    </div>

                    <div className='col-span-12 lg:col-span-2 lg:ps-4'>

                        <h2 className='font-medium mb-4 py-3 bg-[#b04db5] ps-3 text-white'>حسابى</h2>

                        <ul> {renderLists(accountData)} </ul>

                    </div>

                    <div className='col-span-12 lg:col-span-2'>

                        <h2 className='font-medium mb-4 py-3 bg-[#b04db5] ps-3 text-white'>روابط مهمة</h2>

                        <ul> {renderLists(importantLinksData)} </ul>

                    </div>

                    <div className='col-span-12 lg:col-span-2'>

                        <h2 className='font-medium mb-4 py-3 bg-[#b04db5] ps-3 text-white'>تواصل معنا</h2>

                        <ul> {renderLists(contactUsData, true)} </ul>

                    </div>

                    <div className='col-span-12 lg:col-span-3'>

                        <h2 className='font-medium mb-4 py-3 bg-[#b04db5] ps-3 text-white'>تابعنا على</h2>

                        <ul className='flex items-center'>

                            <li className='transition border-2 border-[#9c22a2] hover:!border-[var(--light)] text-[#9c22a2] hover:!text-[var(--light)] rounded-full cursor-pointer flex items-center justify-center w-[40px] h-[40px]'>

                                <FontAwesomeIcon icon={faFacebookF}></FontAwesomeIcon>

                            </li>
                            <li className='mx-3 transition border-2 border-[#9c22a2] hover:!border-[var(--light)] text-[#9c22a2] hover:!text-[var(--light)] rounded-full cursor-pointer flex items-center justify-center w-[40px] h-[40px]'>

                                <FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon>

                            </li>
                            <li className='mx-3 transition border-2 border-[#9c22a2] hover:!border-[var(--light)] text-[#9c22a2] hover:!text-[var(--light)] rounded-full cursor-pointer flex items-center justify-center w-[40px] h-[40px]'>

                                <FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon>

                            </li>
                            <li className='mx-3 transition border-2 border-[#9c22a2] hover:!border-[var(--light)] text-[#9c22a2] hover:!text-[var(--light)] rounded-full cursor-pointer flex items-center justify-center w-[40px] h-[40px]'>

                                <FontAwesomeIcon icon={faSnapchat}></FontAwesomeIcon>

                            </li>

                        </ul>

                    </div>

                </div>

            </div>

        </div>
    )
}
