// importing require modules
import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { Loader } from "../views/components";

// ##Lazy Function To Load Page Easly Importing From React 

// Home Page
const Home = lazy(() => import('../views/pages/Home'));
// Products Page
const Products = lazy(() => import('../views/pages/Products'));
// Product Ensemble Page
const ProductEnsemble = lazy(() => import('../views/pages/ProductEnsemble'));
// Cart Page
const Cart = lazy(() => import('../views/pages/Cart'));

export default function routes() {
    return (
        // Prevent loading errors with fallback Loader
        <Suspense fallback={<Loader />}>

            {/* Route configuration */}
            <Routes>

                {/* Route for homepage */}
                <Route path="" element={<Home />} />

                {/* Route for products page */}
                <Route path="products" element={<Products />} />

                {/* Route for product details page */}
                <Route path="products/products-details" element={<ProductEnsemble />} />

                {/* Route for product details page */}
                <Route path="cart" element={<Cart />} />

                {/* End Of Route configuration */}
            </Routes>

        </Suspense>
    );
}
