import { RadioButton } from 'primereact/radiobutton';
import PropTypes from 'prop-types';

// This Component Will Render The Radio Input Group By Custom Design
export default function RadioGroup(props) {

    const { items, selectedItems, setSelectedItems, itemTemplate } = props;

    // items => will be an array of objects including for each object some keys called {name,key}
    // itemTemplate => Will Render The Design Of component

    return (
        <div>
            {items.map((item) => {

                return (

                    <div key={item.key} className="mb-1 flex items-center justify-between p-2 cursor-pointer">


                        <RadioButton inputId={item.key} className='me-3' name="category" value={item} onChange={(e) => setSelectedItems(e.value)} checked={selectedItems.key === item.key} />

                        <label htmlFor={item.key} className="flex-1 flex items-center justify-between cursor-pointer text-[13px] text-white">

                            {itemTemplate(item)}

                            <div className='gray-text !text-[12px]'>({item?.count})</div>

                        </label>

                    </div>

                );
            })}
        </div>
    );
}


RadioGroup.propTypes = {
    items: PropTypes.array,
    selectedItems: PropTypes.object,
    setSelectedItems: PropTypes.func,
    itemTemplate: PropTypes.func,
}