// This Component Will Render Card Of Commerical Brand
export default function CommercialBrandsCard({ imageSrc }) {
    return (
        <div className='flex items-center justify-center'>

            {/* Card Background Image */}
            <img src={require(`../../../assets/images/${imageSrc}.png`)} alt='' className='select-none object-contain w-full' />

        </div>
    )
}
