import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

// That's Will Render The Head Of Page Footer
export default function JoinWithUs() {
    return (
        <div className='bg-[var(--primary)] p-6'>

            <div className='container grid grid-cols-12 gap-6'>

                <div className='flex items-center col-span-12 xl:col-span-3'>

                    <div className='me-2 min-w-[50px] w-[50px] h-[50px] rounded-full bg-[#4e1151] flex items-center justify-center'>

                        <FontAwesomeIcon icon={faEnvelope} className='text-[20px] text-white'></FontAwesomeIcon>

                    </div>

                    <div>

                        <h2 className='font-medium text-[#cd90d0] mb-1'>الاشتراك فى النشرة البريدية</h2>

                        <p className='!text-[#e6c8e8] max-w-[80%] sm:max-w-full'>انضم الآن واحصل على خصم 10٪ على مشترياتك التالية!</p>

                    </div>

                </div>

                <div className='col-span-12 lg:col-span-8 xl:col-span-6'>

                    <p className='gray-text !text-[#e6c8e8] mb-2'>يمكنك إلغاء الاشتراك في أي لحظة</p>

                    <div className='flex items-center'>

                        <input type='email' className='flex-1 max-w-[60%] sm:max-w-[100%] border p-3 me-3 rounded-[5px] placeholder:text-[var(--gray-color)]' placeholder='ادخل البريد الالكترونى' />

                        <button className='min-btn !bg-white !text-[#9C21A2] !p-3'>إشتراك</button>

                    </div>

                </div>

                <div className='col-span-12 lg:col-span-4 xl:col-span-3'>

                    <h2 className='font-medium text-white mb-3'>تطبيقات الجوال</h2>

                    <div className='flex items-center'>

                        <img src={require(`../../../../../assets/images/apple.png`)} alt='' className='me-3' />
                        <img src={require(`../../../../../assets/images/apple.png`)} alt='' className='me-3' />

                    </div>

                </div>

            </div>

        </div>
    )
}
