import React, { useState } from 'react'
import { useEffectControlar } from '../../../assets/hooks';

// Reset Scroll to zero
const resetScroll = () => {

    window.scrollTo({

        top: 0,

        left: 0,

        behavior: "smooth"

    })

}

// This Will Reset The Scroll Of The Page To Top === 0
export default function UpButton() {

    const [showBtn, setShowBtn] = useState(false);

    const { useUpButton } = useEffectControlar();

    useUpButton(setShowBtn)

    return (
        <button onClick={resetScroll} className={`min-btn border border-white !bg-[var(--primary)] z-[99999999] transition !p-2 !px-5 fixed bottom-3 right-3 ${showBtn ? "opacity-100 scale-100 visible" : "opacity-0 scale-0 invisible"}`}>
            Up
        </button>
    )
}
