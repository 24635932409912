import React from 'react'

export default function Privacy() {
    return (
        <div className='bg-[var(--primary)] p-5'>

            <div className='container'>
                <div className='flex items-center justify-between flex-wrap'>

                    <p className='w-full md:w-fit text-[#FFFFFF] text-[14px]'>الحقوق محفوظة لمنصة سلة © 2023</p>

                    <div className='w-full md:w-fit -order-1 md:order-none mb-5 md:mb-0 flex items-center flex-wrap'>

                        <div className='w-full md:w-fit flex items-center me-3'>

                            <img src={require('../../../../../assets/images/vat.png')} alt='' className='me-2 object-contain' />

                            <p className='text-[#FFFFFF] text-[14px]'>الرقم الضريبي : 546987552</p>

                        </div>

                        <div className='w-full md:w-fit flex items-center mb-2 md:mb-0 -order-1 md:order-none'>

                            <img src={require('../../../../../assets/images/visa.png')} alt='' className='me-2' />

                            <img src={require('../../../../../assets/images/master.png')} alt='' className='me-2' />

                            <img src={require('../../../../../assets/images/paypal.png')} alt='' className='me-2' />

                            <img src={require('../../../../../assets/images/mada.png')} alt='' className='me-2' />

                        </div>


                    </div>

                </div>

            </div>

        </div>
    )
}
