import { useLocation } from 'react-router';
import { useEffectControlar } from '../../../assets/hooks';

// Thats to Reset the scroll to zero when user change the route
export default function ScrollReset() {
    const router = useLocation();

    const { useResetScroll } = useEffectControlar();

    useResetScroll(router);

    return null;
}
