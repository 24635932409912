import React from 'react'
import { JoinWithUs, FooterLinks, Privacy } from './Components'

export default function Footer() {
    return (
        <footer className=' overflow-hidden'>

            {/* Head Of Footer */}
            <JoinWithUs />

            <FooterLinks />

            <Privacy />

        </footer>
    )
}
