// importing require modules
import { Rating } from '../../components'

export default function OpinionsCard() {
    return (
        <div className='border-2 p-3 rounded-[7px]'>

            {/* Author details */}
            <div className='flex items-center'>

                {/* Author image */}
                <img src={require('../../../assets/images/nabowy.jpg')} alt='' className='w-[50px] h-[50px] rounded-full me-5' />

                <div>

                    {/* Author name */}
                    <h2 className='font-medium'>محمود ابراهيم</h2>

                    {/* Author title */}
                    <p className='gray-text !text-[#9C21A2]'>مهندس برمجيات لدى شركة سلة</p>


                </div>

            </div>

            <div className='flex items-center my-5' style={{'--rating-color': "#9C21A2"}}>

                {/* Render rating  */}
                <Rating rate={4} isDisabled={true}></Rating>

                <p className='text-[#9C21A2] ms-2 mb-[-3px]'>(4.5)</p>

            </div>

            {/* Opinion text */}
            <p className='font-medium leading-7 p-3 rounded-[5px] bg-[#f1e8f1]'>
                استخدمنا سلة قبل أربع سنوات ثم غادرناها قبل أن يكون فيها خيارات ترقية مدفوعة، خسرنا مبالغ كبيرة جدا في عمل متجر وتعبنا جدا في التعامل مع المبرمجين . ثم عدنا لمنصة سلة ووجدنا تطورا هائلاً وعملا مميزاً
            </p>



        </div>
    )
}
