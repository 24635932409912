import React, { Fragment, useState } from 'react'
import { handelAnimationCartBtn } from './data';
import './index.css';

export default function AddToCart({ classNames }) {

    const [isAddToCart, setIsAddToCart] = useState(false);


    return (
        <Fragment>

            <button className={`cart-button flex-1 ${classNames}`} onClick={(e) => handelAnimationCartBtn(e, isAddToCart, setIsAddToCart)}>

                <span>Add to cart</span>

                <div className="cart">

                    <svg viewBox="0 0 36 26">

                        <polyline points="1 2.5 6 2.5 10 18.5 25.5 18.5 28.5 7.5 7.5 7.5"></polyline>

                        <polyline points="15 13.5 17 15.5 22 10.5"></polyline>

                    </svg>

                </div>

            </button>

        </Fragment>
    )
}