import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'

// Thats will describe the current section 
export default function SeactionHeader(props) {

    const { href, title, paragraph } = props;

    return (
        <div className='flex items-center justify-between mb-3'>

            <div>
                <h2 className='font-bold text-[14px] sm:text-[32px] text-[var(--light)] mb-2'>{title}</h2>
                <p className='gray-text'>{paragraph}</p>
            </div>

            <Link to={href || '/products'} className='min-btn min-w-[90px] text-center border border-[var(--light)] !bg-transparent text-[13px] lg:text-[16px] !font-normal !p-2 sm:!px-5 !text-[var(--light)] flex items-center'>

                <span className='w-full sm:w-fit'>عرض الكل</span>

                <FontAwesomeIcon icon={faAngleLeft} className='hidden sm:block ms-4'></FontAwesomeIcon>

            </Link>

        </div>
    )
}

SeactionHeader.propTypes = {
    href: PropTypes.string,
    title: PropTypes.string,
    paragraph: PropTypes.string,
}