import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment, useState } from 'react'
import Swal from 'sweetalert2';

export default function AddToFavoriteIcon({ classNames }) {

    const [isFavorite, setIsFavorite] = useState(false);

    const handleFavoriteButton = () => {

        setIsFavorite(!isFavorite);

        // i put ! before the favorite variable because useState in react is async so, we need to handel it 
        if (!isFavorite) {

            Swal.fire({
                icon: 'success',
                title: 'Product Added To Favorite Successfully.',
            })

        } else {

            Swal.fire({
                icon: 'success',
                title: 'Product Removed From Favorite Successfully',
            })

        }
    }


    return (

        <Fragment>

            <button className={`flex items-center justify-center rounded-[5px] border ms-3 p-4 text-[var(--gray-color)] relative z-[100] ${classNames}`} onClick={_ => handleFavoriteButton()}>

                <FontAwesomeIcon icon={faHeart} className={`${isFavorite ? "text-red-500" : ''}`}></FontAwesomeIcon>

            </button>

        </Fragment>
    )
}
