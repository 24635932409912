
import SwiperWrapper from '../SwiperWrapper';


export default function NewsOffers({ classNames, items, title, showLabel }) {

    return (

        <section data-aos="zoom-in"  className={`m-section !my-0 !py-16 bg-[var(--off-white)] top-left-pagination-wrapper custom-pagination-wrapper ${classNames}`}>

            <div className='container'>


                <div className='mt-10 relative top-left-pagination '>

                    <div className='mb-20'>
                        <h2 className='font-bold text-[18px] sm:text-[32px] text-[var(--light)] mb-2'>{title || "وصل حديثا"}</h2>
                        {
                            showLabel ?
                                <p className='gray-text lg:ms-16'>تسوق احدث المنتجات المميزة المضافة جديد</p>
                                : null
                        }
                    </div>

                    <SwiperWrapper
                        items={items}
                        includeNavigation={true}
                        includePagination={true}
                        slidesPerViewCount={[2, 2, 3, 3]}
                        autoplayDelay={3000}
                        isLooped={true}
                    ></SwiperWrapper>

                </div>

            </div>

        </section>
    )
}
